// @ is an alias to /src
import { IStateInteractionsExport } from '@/store/modules/interactions';
import { IExportFile } from '@/models/visitor';
import { addVisitorContactsExport, deleteVisitorContactsExport, getVisitorContactsExports } from '@/api/visitors';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

export default defineComponent({
  data() {
    return {
      exhibitor: 0,
      event: 0,
      visitorExports: [] as IExportFile[],
      interval: 0,
    }
  },
  setup() {
    const { translations, language } = useContext();
    const { userLoggedIn } = useAuth();
    return { translations, userLoggedIn, language };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);

    getVisitorContactsExports(this.exhibitor).then(response => {
      this.visitorExports = response.data.results;
    });
  },
  methods: {
    exportVisitors() {
      addVisitorContactsExport(this.exhibitor).then(response => {
        this.visitorExports = [...this.visitorExports, response.data];
      });
      this.interval = setInterval(() => {
        getVisitorContactsExports(this.exhibitor).then(response => {
          this.visitorExports = response.data.results;
          if (!this.visitorExports.some(visitorExport => visitorExport.building)) {
            clearInterval(this.interval);
          }
        });
      }, 10000)
    },
    getTime(date: string) {
      const newDate = new Date(date);
      return newDate.toLocaleString(this.language, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },

    deleteExport(exportFile: IExportFile) {
      deleteVisitorContactsExport(exportFile.id).then(() => {
        this.visitorExports = this.visitorExports.filter(visitorExport => visitorExport.id !== exportFile.id);
      });
    },

    downloadExport(interactionsExport: IStateInteractionsExport) {

      window.location.href = interactionsExport.url;
    }
  }
})

